





















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import dayjs from 'dayjs'
import controller, { paramsInterface } from '@/app/ui/controllers/MerchantController'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import { Utils } from '@/app/infrastructures/misc'
import {
  MERCHANT_PAGINATION
} from '@/app/infrastructures/misc/Constants/pagination'
import EditIconNoSquare from '@/app/ui/assets/edit_icon_nosquare.vue'
import Eye from '@/app/ui/assets/eye.vue'
import Toggle from '@/app/ui/components/Toggle/index.vue'
import MerchantBadge from './components/MerchantBadge/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import { Dictionary } from 'vue-router/types/router'

@Component({
  components: {
    Button,
    TextInput,
    DropdownSelect,
    DataTable,
    PaginationNav,
    Loading,
    EditIconNoSquare,
    Eye,
    Toggle,
    Modal,
    MerchantBadge,
    EmptyState
  },
})
export default class MerchantListPage extends Vue {
  isGuest = Utils.isGuest()
  controller = controller
  parameters: { page: number; perPage: number; merchantName: string | undefined } = {
    page: 1,
    perPage: MERCHANT_PAGINATION,
    merchantName: undefined,
  }
  headers = [
    'Merchant ID',
    'Merchant Name',
    'Brand',
    'City',
    'Description',
    'Created At',
    'Status'
  ]

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = <Record<string, never>>this.$route.query
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: Utils.alwaysNumber(queries.perPage) || MERCHANT_PAGINATION,
        merchantName: queries.merchantName || undefined,
      }
    }

    this.fetchMerchantList(true)
  }

  get params(): paramsInterface {
    return {
      ...this.parameters,
    }
  }

  get merchantDataTable(): Array<Array<number | string | boolean | undefined>> {
    return controller.merchantData.map(merchant => [
      merchant.id,
      merchant.name,
      merchant.brand,
      merchant.city,
      merchant.description,
      dayjs(merchant.createdAt).format('MM/DD/YYYY hh:mm A'),
      merchant.status
    ])
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  @Watch('parameters.merchantName')
  onKeywordChanged(val: string): void {
    if (val === '') {
      this.parameters.page = 1
      this.parameters.merchantName = undefined
    }
  }

  private fetchMerchantList(resetPage = false): void {
    if (resetPage) {
      this.parameters.page = 1
    }
    this.controller.getMerchantList(this.params)
  }

  private rowClick(row: Array<string | number>): void {
    this.$router.push({
      name: 'MerchantDetailProfilePage',
      params: {
        id: row[0].toString()
      }
    })
  }
}
