









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class MerchantBadge extends Vue {
  @Prop({ default: 'active' }) private type!:
    | 'active'
    | 'suspended'
    | 'registered'
}
